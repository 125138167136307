import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap"; // Importando o Dropdown do React-Bootstrap
import { jwtDecode } from "jwt-decode"; // Importando jwt-decode para extrair informações do token

const Sidebar = () => {
  // Obtém o token JWT do localStorage (ou sessionStorage, se necessário)
  const token = localStorage.getItem("token"); 
  let userRole = null;
  let userTag = null;

  // Decodifica o token para extrair informações
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userRole = decodedToken.role; // Obtém o papel do usuário (exemplo: Administrador)
      userTag = decodedToken.tag; // Obtém a tag do usuário (defina qual tag precisa ocultar "Tickets")
    } catch (error) {
      console.error("Erro ao decodificar o token:", error);
    }
  }

  return (
    <div className="sidebar text-light p-3" style={{ width: "200px" }}>
      {/* Links de navegação */}
      <nav className="nav flex-column">
        <Link className="nav-link text-light" to="/">
          Home
        </Link>
        <Link className="nav-link text-light" to="/dados-cadastrais">
          Meus circuitos
        </Link>
        
        {/* O link "Tickets" será ocultado se a tag corresponder a um valor específico */}
        {!userTag || userTag !== "Procuradoria Geral de Justiça de Minas Gerais" ? (
          <Link className="nav-link text-light" to="/tickets">
            Tickets
          </Link>
        ) : null}

        {/* Link visível apenas para administradores */}
        {userRole === "Administrador" && (
          <Dropdown>
            <Dropdown.Toggle variant="link" className="text-light" id="dropdown-admin">
              Admin
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-dark">
              <Link className="dropdown-item text-light" to="/cadastro-cliente">
                Gerenciar Clientes
              </Link>
              <Link className="dropdown-item text-light" to="/vincular-usuario">
                Gerenciar Usuários
              </Link>
              <Link className="dropdown-item text-light" to="/banco-de-dados">
                Gerenciar Banco
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </nav>
    </div>
  );
};

export default Sidebar;
