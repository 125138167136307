import React, { useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';

const CadastroFuncionario = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    cpf: '',
    telefone: '',
    cargo: '',
    departamento: '',
    dataAdmissao: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aqui você pode enviar os dados para sua API
    
    alert('Funcionário cadastrado com sucesso!');
    setFormData({
      nome: '',
      email: '',
      cpf: '',
      telefone: '',
      cargo: '',
      departamento: '',
      dataAdmissao: '',
    });
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Cadastro de Funcionário</h1>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="nome">
              <Form.Label>Nome Completo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome completo"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Digite o email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="cpf">
              <Form.Label>CPF</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o CPF"
                name="cpf"
                value={formData.cpf}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="telefone">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o telefone"
                name="telefone"
                value={formData.telefone}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="cargo">
              <Form.Label>Cargo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o cargo"
                name="cargo"
                value={formData.cargo}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="departamento">
              <Form.Label>Departamento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o departamento"
                name="departamento"
                value={formData.departamento}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="dataAdmissao">
              <Form.Label>Data de Admissão</Form.Label>
              <Form.Control
                type="date"
                name="dataAdmissao"
                value={formData.dataAdmissao}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Cadastrar Funcionário
        </Button>
      </Form>
    </Container>
  );
};

export default CadastroFuncionario;
