import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal from "sweetalert2";
import { jwtDecode } from 'jwt-decode'; // Importação correta do jwt-decode
import { UserContext } from '../context/UserContext';

function Header({ title }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const handlePerfilClick = () => {
    navigate('/perfil');
  };

  const handleLogout = () => {
    Swal.fire({
      icon: 'success',
      title: 'Logout realizado!',
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      localStorage.removeItem('token');
      navigate('/login');
    });
  };

  // Decodifica o token utilizando useMemo para não recalcular em cada renderização
  const decodedToken = useMemo(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        return jwtDecode(token);
      } catch (error) {
        console.error("Erro ao decodificar token:", error);
      }
    }
    return null;
  }, []);



  // Verifica se o usuário é da Procuradoria com base na tag do token
  const isProcuradoria =
    decodedToken &&
    decodedToken.tag &&
    decodedToken.tag.trim().toLowerCase() === 'procuradoria geral de justiça de minas gerais';

  // Escolhe a logo de acordo com a tag
  const logoSource = isProcuradoria
    ? '/imagens/logo-algar.png'
    : '/imagens/logo_dot.png';


  return (
    <header
      className="d-flex align-items-center p-3 shadow text-white"
      style={{ backgroundColor: 'var(--color-primary)' }}
    >
      {/* Logo (à esquerda) */}
      <div className="d-flex align-items-center">
        <img
          src={logoSource}
          alt="Logo"
          style={{ height: '45px', marginRight: '10px' }}
        />
      </div>

      {/* Título centralizado */}
      <h1 className="flex-grow-1 text-center mb-0" style={{ fontSize: '1.8rem' }}>
        {title}
      </h1>

      {/* Dropdown de usuário (à direita) */}
      <div className="user-info d-flex align-items-center ms-3">
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-user"
            className="text-black"
            style={{
              backgroundColor: 'transparent',
              color: 'white',
              border: '1px solid var(--color-secondary)',
              fontWeight: 'bold',
              padding: '8px 20px',
              borderRadius: '6px'
            }}
          >
            {user?.nome_usuario || "Usuário Desconhecido"}
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={handlePerfilClick}>
              <i className="bi bi-person-circle me-2"></i> Editar Perfil
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              <i className="bi bi-box-arrow-right me-2"></i> Sair
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}

export default Header;
