import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode'; // Importação correta
import 'bootstrap/dist/css/bootstrap.min.css';

import Sidebar from './components/Sidebar';
import Header from './components/Header';
import DadosCadastrais from './pages/DadosCadastrais';
import Tickets from './pages/Tickets';
import PerfilUsuario from './pages/PerfilUsuario';
import CadastroFuncionario from './pages/CadastroFuncionarios';
import AdminPage from './pages/AdminDashboard';
import Login from './pages/Login';
import CadastroCliente from './pages/CadastroCliente.jsx';
import VincularUsuario from './pages/VincularUsuario';
import Bancodedados from './pages/bancodedados';
import { UserProvider, UserContext } from "./context/UserContext";

import './App.css';

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setUser } = useContext(UserContext);
  const [themeClass, setThemeClass] = useState('default-theme');

  // Verifica token no localStorage ao carregar a aplicação
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUser(decoded);
        setIsLoggedIn(true);

        // Verifica a tag e define o tema
        if (
          decoded.tag &&
          decoded.tag.trim().toLowerCase() === 'procuradoria geral de justiça de minas gerais'
        ) {
          setThemeClass('procuradoria-theme');
        } else {
          setThemeClass('default-theme');
        }
      } catch (error) {
        console.error("Token inválido ou expirado.");
        localStorage.removeItem('token');
        setIsLoggedIn(false);
      }
    }
  }, [setUser]);

  const handleLogin = (token) => {
    const decoded = jwtDecode(token);
    localStorage.setItem('token', token);
    setUser(decoded);
    setIsLoggedIn(true);
    

    // Define o tema após o login
    if (
      decoded.tag &&
      decoded.tag.trim().toLowerCase() === 'procuradoria geral de justiça de minas gerais'
    ) {
      setThemeClass('procuradoria-theme');
    } else {
      setThemeClass('default-theme');
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    setThemeClass('default-theme');
  };

  // Rota protegida para verificar autenticação
  const ProtectedRoute = ({ children }) => {
    return isLoggedIn ? children : <Navigate to="/login" />;
  };

  // Layout com Header, Sidebar e Footer
  const ProtectedLayout = () => (
    <div className="app d-flex">
      <Sidebar />
      <div className="main-content flex-grow-1">
        <Header title="Portal do Cliente" onLogout={handleLogout} />
        <Outlet />
      </div>
    </div>
  );

  function HomePage() {
    const { user } = useContext(UserContext);
  
    return (
      <Container className="my-5">
        <Row>
          {user?.tag?.trim().toLowerCase() === 'procuradoria geral de justiça de minas gerais' ? (
            // Exibe uma imagem para Procuradoria
            <Col md={12} className="text-center">
              <img 
                src="/imagens/procuradoria-algar.jpg" 
                alt="Procuradoria" 
                className="img-fluid"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Col>
          ) : (
            <>
              {/* Coluna esquerda: Serviços */}
              <Col md={6}>
                <h2>Serviços</h2>
                <ul>
                  <li>Internet</li>
                  <li>Lan to Lan</li>
                  <li>SD Wan</li>
                  <li>Consultoria</li>
                  <li>Outsourcing</li>
                  <li>Cloud Computing</li>
                  <li>Wi-Fi</li>
                  <li>Telecom</li>
                </ul>
              </Col>
              {/* Coluna direita: Contatos */}
              <Col md={6}>
                <h2>Contatos</h2>
                <p><strong>Telefone:</strong> (xx) xxxx-xxxx</p>
                <p><strong>E-mail:</strong> </p>
                <p><strong>WhatsApp:</strong> (xx) xxxxx-xxxx</p>
                <p>
                  <strong>Site:</strong>{' '}
                  <a href="https://dotadot.com.br" target="_blank" rel="noopener noreferrer">
                    site
                  </a>
                </p>
              </Col>
            </>
          )}
        </Row>
      </Container>
    );
  };
  

  return (
    // Aplica a classe de tema condicionalmente na raiz da aplicação
    <div className={`app-container ${themeClass}`}>
      <Router>
        <Routes>
          {/* Rota de Login */}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />

          {/* Layout com Sidebar e Header para rotas protegidas */}
          <Route path="/" element={<ProtectedRoute><ProtectedLayout /></ProtectedRoute>}>
            {/* Página Inicial (Home) */}
            <Route index element={<HomePage />} />

            {/* Sub-rotas protegidas */}
            <Route path="dados-cadastrais" element={<DadosCadastrais />} />
            <Route path="tickets" element={<Tickets />} />
            <Route path="perfil" element={<PerfilUsuario />} />
            <Route path="cadastro-funcionario" element={<CadastroFuncionario />} />
            <Route path="cadastro-cliente" element={<CadastroCliente />} />
            <Route path="vincular-usuario" element={<VincularUsuario />} />
            <Route path="banco-de-dados" element={<Bancodedados />} />
            {/* Página de Administração */}
            <Route path="admin" element={<AdminPage />} />
          </Route>

          {/* Rota 404 */}
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
      </Router>
    </div>
  );
}

// Wrapping the entire App in UserProvider
function App() {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
}

export default App;
