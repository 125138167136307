import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decoded = jwtDecode(token);
          const now = Date.now() / 1000;
          if (decoded.exp > now) {
            setUser(decoded);
            navigate("/");
          } else {
            localStorage.removeItem("token");
          }
        } catch (error) {
          console.error("Erro ao decodificar o token:", error);
          localStorage.removeItem("token");
        }
      }
    };

    checkToken();
  }, [setUser, navigate]);  // Dependências corretas para o useEffect

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://api.dotadot.com.br/api/v1/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const decoded = jwtDecode(data.token);

        Swal.fire({
          icon: "success",
          title: "Login bem-sucedido!",
          text: `Bem-vindo, ${decoded.name || username}!`,
        });

        localStorage.setItem("token", data.token);
        setUser(decoded);
        navigate("/");
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Erro de Login",
          text: errorData.detail || "Credenciais inválidas. Tente novamente.",
        });
      }
    } catch (error) {
      console.error("Erro de conexão:", error);
      Swal.fire({
        icon: "error",
        title: "Erro de Conexão",
        text: "Não foi possível conectar ao servidor. Tente novamente mais tarde.",
      });
    }
  };


  return (
    <div className="login-container d-flex vh-100">
      {/* Seção Esquerda */}
      <div className="left-section d-flex justify-content-center align-items-center flex-column">
        
        <h2 className="text-white">Novo por aqui?</h2>
        <p className="text-white">Solicite seu login ao seu gerente de contas!</p>
        
      </div>

      {/* Seção Direita (Formulário de Login) */}
      <div className="right-section d-flex justify-content-center align-items-center">
        <div className="login-box p-4 shadow bg-white rounded">
          <h3 className="text-center mb-4">Login</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                id="username"
                className="form-control"
                placeholder="Usuário"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Acessar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
