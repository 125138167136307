import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Card, Row, Col, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { UserContext } from '../context/UserContext';

const PerfilUsuario = () => {
  const { user, setUser } = useContext(UserContext);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    senha: '',
    confirmarSenha: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Função para validar os dados do formulário
  const validateForm = () => {
    if (formData.senha && formData.senha !== formData.confirmarSenha) {
      Swal.fire('Erro', 'As senhas não coincidem!', 'error');
      return false;
    }
    if (!formData.nome.trim() || !formData.email.trim()) {
      Swal.fire('Erro', 'Nome e email são obrigatórios!', 'error');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!user?.id_usuario) return;

    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`https://api.dotadot.com.br/api/v1/usuarios/${user.id_usuario}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setFormData({
            nome: data.nome_usuario,
            email: data.email,
            telefone: data.telefone || '',
            senha: '',
            confirmarSenha: ''
          });
        } else {
          Swal.fire('Erro', 'Falha ao carregar os dados do usuário.', 'error');
        }
      } catch (error) {
        Swal.fire('Erro', 'Erro ao conectar ao servidor.', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsSubmitting(true);

    const payload = {
      nome_usuario: formData.nome.trim(),
      email: formData.email.trim(),
      telefone: formData.telefone || null,
      ...(formData.senha && { password: formData.senha })
    };

    try {
      const response = await fetch(`https://api.dotadot.com.br/api/v1/usuarios/${user.id_usuario}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const updatedUser = { ...user, nome_usuario: formData.nome, email: formData.email };
        setUser(updatedUser);
        Swal.fire('Sucesso', 'Perfil atualizado com sucesso!', 'success');
      } else {
        const error = await response.json();
        Swal.fire('Erro', error.detail || 'Falha ao atualizar o perfil.', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Meu Perfil</h1>
      <Card>
        <Card.Body>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formNome">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      name="nome"
                      value={formData.nome}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formTelefone">
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control
                      type="text"
                      name="telefone"
                      value={formData.telefone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formSenha">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="password"
                      name="senha"
                      value={formData.senha}
                      onChange={handleChange}
                      placeholder="Deixe em branco para não alterar"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formConfirmarSenha">
                    <Form.Label>Confirmar Senha</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmarSenha"
                      value={formData.confirmarSenha}
                      onChange={handleChange}
                      placeholder="Deixe em branco para não alterar"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Salvando...' : 'Salvar Alterações'}
              </Button>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PerfilUsuario;
