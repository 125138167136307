import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';


const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [clientes, setClientes] = useState([]); // Estado para armazenar os clientes
  const [editUser, setEditUser] = useState(null); // Usuário em edição
  const [showEditModal, setShowEditModal] = useState(false); // Controle do modal de edição
  const [selectedCNPJ, setSelectedCNPJ] = useState(""); // CNPJ selecionado
 

  // Buscar usuários ao carregar a página
  useEffect(() => {
    fetchUsers();
    fetchClientes();
  }, []);

  // Função para buscar todos os clientes
  const fetchClientes = async () => {
    try {
      const response = await fetch('https://api.dotadot.com.br/api/v1/clientes', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setClientes(data); // Armazena os clientes no estado
      } else {
        Swal.fire('Erro', 'Falha ao buscar clientes.', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  // Função para buscar todos os usuários
  const fetchUsers = async () => {
    try {
      const response = await fetch('https://api.dotadot.com.br/api/v1/users', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        Swal.fire('Erro', 'Falha ao buscar usuários.', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  // Excluir um usuário
  const handleDelete = async (id) => {
    const confirm = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Essa ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir!',
    });

    if (confirm.isConfirmed) {
      try {
        const response = await fetch(`https://api.dotadot.com.br/api/v1/users/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          Swal.fire('Sucesso', 'Usuário excluído com sucesso!', 'success');
          setUsers(users.filter((u) => u.id_usuario !== id));
        } else {
          Swal.fire('Erro', 'Falha ao excluir usuário.', 'error');
        }
      } catch (error) {
        Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
      }
    }
  };

  // Iniciar edição
  const startEdit = (user) => {
    setEditUser(user);
    setSelectedCNPJ(user.cnpj); // Definir o CNPJ baseado no usuário atual
    setShowEditModal(true);
  };

  // Lidar com a atualização do usuário
  const handleEdit = async (e) => {
    e.preventDefault();
    const form = e.target;

    // Cria o objeto que será enviado com os campos esperados pelo backend
    const updatedUser = {
      nome_usuario: form.elements['nome'].value,
      email: form.elements['email'].value,
      username: form.elements['username'].value,
      role: form.elements['role'].value,
      cnpj: selectedCNPJ, // Usar o CNPJ selecionado
    };

    try {
      const response = await fetch(`https://api.dotadot.com.br/api/v1/users/${editUser.id_usuario}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(updatedUser),
      });

      if (response.ok) {
        Swal.fire('Sucesso', 'Usuário atualizado com sucesso!', 'success');
        setShowEditModal(false);
        fetchUsers();
      } else {
        const errorData = await response.json();
        Swal.fire('Erro', errorData.detail || 'Falha ao atualizar usuário.', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  // Função para lidar com a mudança no cliente selecionado
  const handleClienteSelect = (e) => {
    const selectedCliente = clientes.find(cliente => cliente.cnpj === e.target.value);
    setSelectedCNPJ(selectedCliente ? selectedCliente.cnpj : "");
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Administração de Usuários</h1>
      <Table striped bordered hover responsive>
        <thead className="bg-primary text-white">
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Username</th>
            <th>Email</th>
            <th>Função</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id_usuario}>
              <td>{user.id_usuario}</td>
              <td>{user.nome_usuario}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <Button variant="warning" size="sm" onClick={() => startEdit(user)} className="me-2">
                  Editar
                </Button>
                <Button variant="danger" size="sm" onClick={() => handleDelete(user.id_usuario)}>
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
          {users.length === 0 && (
            <tr>
              <td colSpan="6" className="text-center">
                Nenhum usuário encontrado.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {showEditModal && (
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEdit}>
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control type="text" defaultValue={editUser?.nome_usuario || ''} name="nome" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" defaultValue={editUser?.email || ''} name="email" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" defaultValue={editUser?.username || ''} name="username" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Função</Form.Label>
                <Form.Control type="text" defaultValue={editUser?.role || ''} name="role" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Cliente</Form.Label>
                <Form.Control as="select" name="cnpj" defaultValue={selectedCNPJ} onChange={handleClienteSelect} required>
                  <option value="">Selecione um Cliente</option>
                  {clientes.map((cliente) => (
                    <option key={cliente.cnpj} value={cliente.cnpj}>
                      {cliente.razao_social} - {cliente.cnpj}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>CNPJ</Form.Label>
                <Form.Control type="text" value={selectedCNPJ} disabled readOnly />
              </Form.Group>
              <Button variant="primary" type="submit">
                Salvar Alterações
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

export default AdminDashboard;
