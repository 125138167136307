import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Table, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const CadastroCliente = () => {
  const [formData, setFormData] = useState({
    razao_social: '',
    nome_fantasia: '',
    cnpj: '',
    status: 'ATIVO',
    id_zendesk: '',
    id_omie: '',
    codigo_cliente: '',
  });

  const [clientes, setClientes] = useState([]); // Estado para armazenar os clientes do banco
  const [selectedClient, setSelectedClient] = useState(null); // Estado para o cliente selecionado
  const [showEditModal, setShowEditModal] = useState(false); // Estado para mostrar o modal de edição

  // Função para buscar todos os clientes cadastrados
  const fetchClientes = async () => {
    try {
      const response = await fetch('https://api.dotadot.com.br/api/v1/clientes_adm', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setClientes(data); // Armazena os clientes no estado
      } else {
        const error = await response.json();
        Swal.fire('Erro', error.detail || 'Erro ao carregar clientes', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  // Função para editar um cliente
  const editClient = (client) => {
    setSelectedClient(client);
    setFormData({
      razao_social: client.razao_social,
      nome_fantasia: client.nome_fantasia,
      cnpj: client.cnpj,
      status: client.status,
      id_zendesk: client.id_zendesk,
      id_omie: client.id_omie,
      codigo_cliente: client.codigo_cliente,
    });
    setShowEditModal(true);
  };

  // Função para tratar a mudança nos campos do formulário
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Função para tratar o envio do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.dotadot.com.br/api/v1/clientes_adm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        Swal.fire('Sucesso', 'Cliente cadastrado com sucesso!', 'success');
        setFormData({
          razao_social: '',
          nome_fantasia: '',
          cnpj: '',
          status: 'ATIVO',
          id_zendesk: '',
          id_omie: '',
          codigo_cliente: '',
        });
        fetchClientes(); // Recarrega a lista de clientes após o cadastro
      } else {
        const error = await response.json();
        Swal.fire('Erro', error.detail || 'Erro ao cadastrar o cliente', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  // Função para lidar com a atualização do cliente
  const handleUpdateClient = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://api.dotadot.com.br/api/v1/clientes_adm/${selectedClient.id_cliente}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        Swal.fire('Sucesso', 'Cliente atualizado com sucesso!', 'success');
        setShowEditModal(false);
        fetchClientes();
      } else {
        const error = await response.json();
        Swal.fire('Erro', error.detail || 'Erro ao atualizar o cliente', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  // Carrega os clientes ao iniciar o componente
  useEffect(() => {
    fetchClientes();
  }, []);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h2>Cadastrar Cliente</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Razão Social</Form.Label>
              <Form.Control
                type="text"
                name="razao_social"
                value={formData.razao_social}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Nome Fantasia</Form.Label>
              <Form.Control
                type="text"
                name="nome_fantasia"
                value={formData.nome_fantasia}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>CNPJ</Form.Label>
              <Form.Control
                type="text"
                name="cnpj"
                value={formData.cnpj}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formData.status}
                onChange={handleChange}
                required
              >
                <option value="ATIVO">Ativo</option>
                <option value="INATIVO">Inativo</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Código do Cliente</Form.Label>
              <Form.Control
                type="text"
                name="codigo_cliente"
                value={formData.codigo_cliente}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>ID Zendesk</Form.Label>
              <Form.Control
                type="text"
                name="id_zendesk"
                value={formData.id_zendesk}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>ID Omie</Form.Label>
              <Form.Control
                type="text"
                name="id_omie"
                value={formData.id_omie}
                onChange={handleChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Cadastrar Cliente
            </Button>
          </Form>

          <h3 className="mt-5">Clientes Cadastrados</h3>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Razão Social</th>
                <th>Nome Fantasia</th>
                <th>CNPJ</th>
                <th>Status</th>
                <th>Código Cliente</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {clientes.length > 0 ? (
                clientes.map((cliente) => (
                  <tr key={cliente.id_cliente}>
                    <td>{cliente.razao_social}</td>
                    <td>{cliente.nome_fantasia}</td>
                    <td>{cliente.cnpj}</td>
                    <td>{cliente.status}</td>
                    <td>{cliente.codigo_cliente}</td>
                    <td>
                      <Button variant="info" onClick={() => editClient(cliente)}>
                        Editar
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    Nenhum cliente cadastrado.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Editar Cliente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleUpdateClient}>
                <Form.Group className="mb-3">
                  <Form.Label>Razão Social</Form.Label>
                  <Form.Control
                    type="text"
                    name="razao_social"
                    value={formData.razao_social}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Nome Fantasia</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome_fantasia"
                    value={formData.nome_fantasia}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>CNPJ</Form.Label>
                  <Form.Control
                    type="text"
                    name="cnpj"
                    value={formData.cnpj}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    required
                  >
                    <option value="ATIVO">Ativo</option>
                    <option value="INATIVO">Inativo</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Código do Cliente</Form.Label>
                  <Form.Control
                    type="text"
                    name="codigo_cliente"
                    value={formData.codigo_cliente}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>ID Zendesk</Form.Label>
                  <Form.Control
                    type="text"
                    name="id_zendesk"
                    value={formData.id_zendesk}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>ID Omie</Form.Label>
                  <Form.Control
                    type="text"
                    name="id_omie"
                    value={formData.id_omie}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Salvar Alterações
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default CadastroCliente;
