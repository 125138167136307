import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Spinner, Alert, Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FaListAlt, FaSearch, FaMapMarkerAlt, FaCity, FaUserTag } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';
import pLimit from 'p-limit';
import 'animate.css';

const DadosCadastrais = () => {
  const [ativos, setAtivos] = useState([]);
  const [filteredAtivos, setFilteredAtivos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAtivo, setSelectedAtivo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [zabbixLoaded, setZabbixLoaded] = useState(false);

  const token = localStorage.getItem('token');

  const decodedToken = useMemo(() => {
    if (!token) return null;
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
      return null;
    }
  }, [token]);

  const isProcuradoria = useMemo(() => {
    return (
      decodedToken &&
      decodedToken.tag &&
      decodedToken.tag.trim().toLowerCase() === 'procuradoria geral de justiça de minas gerais'
    );
  }, [decodedToken]);

  // Para exibição: se for Procuradoria, exibe designacaocliente; senão, designacao
  const getDesignacao = (ativo) => {
    return isProcuradoria ? ativo.designacaocliente : ativo.designacao;
  };

  const getUserFilters = useCallback(() => {
    if (!decodedToken) return [];
    const tag = decodedToken.tag;
    if (tag && tag.trim().toLowerCase() === 'procuradoria geral de justiça de minas gerais') {
      return tag.split(',').map((t) => t.trim().toLowerCase());
    }
    return [];
  }, [decodedToken]);

  // Consulta a rota /consulta-ativos para buscar os ativos (dados do cliente)
  const fetchAtivos = useCallback(async () => {
    const userFilters = getUserFilters();
    try {
      const response = await fetch('https://api.dotadot.com.br/api/v1/consulta-ativos', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Erro ao buscar os ativos.');
      const data = await response.json();

      let filteredData;
      if (isProcuradoria) {
        filteredData = data.filter((ativo) => {
          if (!ativo.cliente_final) return false;
          const ativoFilter = String(ativo.cliente_final).toLowerCase();
          return userFilters.some((filter) => ativoFilter === filter);
        });
      } else {
        filteredData = data;
      }

      setAtivos(filteredData);
      setFilteredAtivos(filteredData);
    } catch (error) {
      Swal.fire('Erro', error.message, 'error');
    }
  }, [getUserFilters, token, isProcuradoria]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAtivos();
      } catch (error) {
        console.error('Erro ao buscar os dados:', error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [fetchAtivos]);

  // Consulta o Zabbix para um ativo específico
  // Utiliza o campo "designacao" original para consulta
  const handleLoadZabbixStatus = useCallback(async (ativo) => {
    const designacaoForZabbix = ativo.designacao;
    if (!designacaoForZabbix) return ativo;
    try {
      const response = await fetch(
        `https://api.dotadot.com.br/api/v1/consulta-zabbix?designacao=${encodeURIComponent(designacaoForZabbix)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const zabbixData = await response.json();
        return { ...ativo, zabbix_link_status: zabbixData.zabbix_link_status };
      } else {
        return { ...ativo, zabbix_link_status: 'Erro' };
      }
    } catch (error) {
      return { ...ativo, zabbix_link_status: 'Erro' };
    }
  }, [token]);

  // Após os ativos carregarem, inicia a consulta ao Zabbix (limitada a 5 requisições simultâneas)
  useEffect(() => {
    if (!isLoading && ativos.length > 0 && !zabbixLoaded) {
      const limit = pLimit(5);
      const timeoutId = setTimeout(() => {
        const tasks = ativos.map((ativo) => limit(() => handleLoadZabbixStatus(ativo)));
        Promise.all(tasks).then((updatedAtivos) => {
          setAtivos(updatedAtivos);
          setFilteredAtivos(updatedAtivos);
          setZabbixLoaded(true);
        });
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [isLoading, ativos, zabbixLoaded, handleLoadZabbixStatus]);

  // Atualização global de status com pop-up de carregamento
  const handleAtualizarStatus = useCallback(() => {
    Swal.fire({
      title: 'Carregando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const limit = pLimit(5);
    const tasks = ativos.map((ativo) => limit(() => handleLoadZabbixStatus(ativo)));
    Promise.all(tasks).then((updatedAtivos) => {
      setAtivos(updatedAtivos);
      setFilteredAtivos(updatedAtivos);
      Swal.close();
    });
  }, [ativos, handleLoadZabbixStatus]);



  const handleShowDetails = (ativo) => {
    setSelectedAtivo(ativo);
    setIsModalOpen(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = ativos.filter((ativo) => {
      return (
        getDesignacao(ativo)?.toLowerCase().includes(value) ||
        ativo.nome_do_ativo?.toLowerCase().includes(value) ||
        ativo.endereco?.toLowerCase().includes(value)
      );
    });
    setFilteredAtivos(filtered);
  };

  return (
    <div className="container-fluid px-1 py-1">
      {/* Botão para atualizar status geral */}
      <div className="mb-3">
        <Button variant="primary" onClick={handleAtualizarStatus}>
          Atualizar Status dos Ativos
        </Button>
      </div>

      {/* Lista de Ativos com Campo de Pesquisa */}
      <div className="card shadow-lg border-0 rounded-3 mb-5 animate__animated animate__fadeIn">
        <div className="card-header bg-primary-gradient d-flex justify-content-between align-items-center py-3">
          <h5 className="mb-0 text-white fw-semibold">
            <FaListAlt className="me-2 text-white" /> Circuitos
          </h5>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              placeholder="Pesquisar por designação, nome ou endereço..."
              value={searchTerm}
              onChange={handleSearch}
              className="ms-3"
            />
            <FaSearch className="ms-2 text-white" size={20} />
          </div>
        </div>

        <div className="card-body p-4">
          {isLoading ? (
            <div className="text-center py-5">
              <Spinner animation="border" variant="primary" size="lg" />
            </div>
          ) : filteredAtivos.length > 0 ? (
            <div className="table-responsive">
              <Table hover className="align-middle">
                <thead className="bg-light">
                  <tr>
                    <th className="py-3">
                      <FaUserTag className="me-2" /> Designação
                    </th>
                    <th className="py-3">
                      <FaMapMarkerAlt className="me-2" /> Endereço
                    </th>
                    <th className="py-3">
                      <FaCity className="me-2" /> Cidade
                    </th>
                    <th className="py-3">UF</th>
                    <th className="py-3">Usuário PPPOE</th>
                    <th className="py-3">Senha</th>
                    <th className="py-3">IP</th>
                    <th className="py-3">Gateway</th>
                    <th className="py-3">Mascara</th>
                    <th className="py-3">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAtivos.map((ativo, index) => (
                    <tr
                      key={index}
                      className="animate__animated animate__fadeInUp"
                      onClick={() => handleShowDetails(ativo)}
                    >
                      <td className="py-2" data-label="Designação">
                        {getDesignacao(ativo)}
                      </td>
                      <td className="py-2" data-label="Endereço">
                        {ativo.endereco}
                      </td>
                      <td className="py-2" data-label="Cidade">
                        {ativo.cidade}
                      </td>
                      <td className="py-2" data-label="UF">
                        <span className="badge bg-secondary">{ativo.uf}</span>
                      </td>
                      <td className="py-2" data-label="Usuário PPPOE">
                        {ativo.usuario}
                      </td>
                      <td className="py-2" data-label="Senha">
                        {ativo.senha}
                      </td>
                      <td className="py-2" data-label="IP">
                        {ativo.n_de_ip}
                      </td>
                      <td className="py-2" data-label="Gateway">
                        {ativo.gateway}
                      </td>
                      <td className="py-2" data-label="Mascara">
                        {ativo.mascara}
                      </td>
                      <td className="py-2" data-label="Status">
                        <span
                          className={`badge ${
                            ativo.zabbix_link_status === 'Up' ? 'bg-success' : 'bg-danger'
                          }`}
                        >
                          {ativo.zabbix_link_status || 'Sem Monitoramento'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Alert variant="warning" className="text-center">
              Nenhum ativo encontrado.
            </Alert>
          )}
        </div>
      </div>

      {/* Modal de Detalhes */}
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        centered
        dialogClassName="modal-90w"
        fullscreen={true}
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title className="fw-bold">Detalhes do Circuito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAtivo ? (
            <div className="container-fluid">
              <div className="row">
                {/* Coluna esquerda */}
                <div className="col-md-4">
                  <h3 className="mb-4 text-primary">Informações de Cadastro</h3>
                  <p>
                    <strong>Designação:</strong>{' '}
                    {getDesignacao(selectedAtivo) || 'Não disponível'}
                  </p>
                  <p>
                    <strong>Endereço:</strong>{' '}
                    {selectedAtivo.endereco || 'Não disponível'}
                  </p>
                  <p>
                    <strong>Cidade:</strong>{' '}
                    {selectedAtivo.cidade || 'Não disponível'}
                  </p>
                  <p>
                    <strong>UF:</strong>{' '}
                    {selectedAtivo.uf || 'Não disponível'}
                  </p>
                  <p>
                    <strong>Status:</strong>
                    <span
                      className={`badge ${
                        selectedAtivo.status === 'ATIVO' ? 'bg-success' : 'bg-danger'
                      } ms-2`}
                    >
                      {selectedAtivo.status || 'Não disponível'}
                    </span>
                  </p>
                  <h3 className="mt-5 mb-4 text-primary">Informações Técnicas</h3>
                  <p>
                    <strong>IP:</strong>{' '}
                    {selectedAtivo.n_de_ip || 'Não disponível'}
                  </p>
                  <p>
                    <strong>Usuário:</strong>{' '}
                    {selectedAtivo.usuario || 'Não disponível'}
                  </p>
                  <p>
                    <strong>Senha:</strong>{' '}
                    {selectedAtivo.senha || 'Não disponível'}
                  </p>
                  <p>
                    <strong>Gateway:</strong>{' '}
                    {selectedAtivo.gateway || 'Não disponível'}
                  </p>
                  <p>
                    <strong>Mascara:</strong>{' '}
                    {selectedAtivo.mascara || 'Não disponível'}
                  </p>
                </div>
                {/* Coluna direita */}
                <div className="col-md-8">
                  <h3 className="mb-4 text-primary">Monitoramento</h3>
                  {selectedAtivo.dashboardurl ? (
                    <iframe
                      title="Grafana Dashboard"
                      src={selectedAtivo.dashboardurl}
                      style={{ width: '100%', height: '85vh', border: 'none' }}
                    />
                  ) : (
                    <p>
                      Monitoramento não disponível, por favor entre em contato com Suporte
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Alert variant="danger" className="mt-3">
              Não foi possível carregar os detalhes do circuito.
            </Alert>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DadosCadastrais;
