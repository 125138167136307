import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

// Criação do contexto
export const UserContext = createContext();

// Provedor de contexto do usuário
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUser(decoded);
      } catch (error) {
        console.error("Erro ao decodificar o token:", error);
        localStorage.removeItem("token");
      }
    }
  }, []);
  
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Garante que o UserProvider também seja exportado como padrão
export default UserProvider;
