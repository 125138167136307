import React, { useState, useEffect } from "react";
import { Table, Form, Button, Row, Col, Card, Spinner, Alert, Modal } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import Swal from "sweetalert2";
import "./Tickets.css"; // Arquivo CSS para estilos personalizados
import { jwtDecode } from "jwt-decode";

const Tickets = () => {
  const [formData, setFormData] = useState({
    contato: "",
    email: "",
    telefone: "",
    assunto: "",
    servico: "",
    descricao: "",
    ativo: "",
  });

  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [ticketComments, setTicketComments] = useState([]);

  // Decodifica o token para pré-preencher o campo "tag" se disponível
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded.tag) {
          setFormData((prevData) => ({ ...prevData, tag: decoded.tag }));
        }
      } catch (error) {
        console.error("Erro ao decodificar o token:", error);
      }
    }
  }, []);

  const fetchTicketDetails = async (ticketId) => {
    setIsLoadingDetails(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://api.dotadot.com.br/api/v1/detalhes-ticket/${ticketId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Erro ao buscar os detalhes do ticket.");
      }
      const data = await response.json();
      setTicketDetails(data.ticket);
      setTicketComments(data.comments);
    } catch (error) {
      setError("Erro ao carregar detalhes do ticket.");
    } finally {
      setIsLoadingDetails(false);
    }
  };

  useEffect(() => {
    const fetchUserInfoAndAtivos = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token não encontrado.");
        }
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const userId = decodedToken.id_usuario;

        const userResponse = await fetch(`https://api.dotadot.com.br/api/v1/usuarios/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!userResponse.ok) {
          throw new Error("Erro ao buscar as informações do usuário.");
        }
        const userData = await userResponse.json();
        setFormData((prevData) => ({
          ...prevData,
          contato: userData.nome_usuario,
          email: userData.email,
          telefone: userData.telefone,
        }));

        const ticketsResponse = await fetch(`https://api.dotadot.com.br/api/v1/consulta-tickets`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!ticketsResponse.ok) {
          throw new Error("Erro ao buscar os tickets do cliente.");
        }
        const ticketsData = await ticketsResponse.json();
        setTickets(ticketsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfoAndAtivos();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!formData.assunto || !formData.servico || !formData.descricao || !formData.ativo) {
      setError("Por favor, preencha todos os campos!");
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const payload = {
      subject: formData.assunto,
      body: `${formData.descricao}\n\nContato: ${formData.contato}\nEmail: ${formData.email}\nTelefone: ${formData.telefone}\nAtivo: ${formData.ativo}\n`,
      priority: "normal",
      requester_email: formData.email,
    };

    try {
      const response = await fetch("https://api.dotadot.com.br/api/v1/criar-ticket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Erro ao criar o ticket. Tente novamente.");
      }
      setSuccess(true);
      setFormData((prevData) => ({
        ...prevData,
        assunto: "",
        servico: "",
        descricao: "",
        ativo: "",
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <Row>
        <Col md={12}>
          <Card className="shadow-sm rounded">
            <Card.Header className="text-white">
              <h5>Abertura de Ticket</h5>
            </Card.Header>
            <Card.Body>
              <CSSTransition in={!!error} timeout={300} classNames="alert" unmountOnExit>
                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                  {error}
                </Alert>
              </CSSTransition>
              <CSSTransition in={success} timeout={300} classNames="alert" unmountOnExit>
                <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
                  Ticket criado com sucesso!
                </Alert>
              </CSSTransition>
              <Form>
                <Form.Group as={Row} className="mb-3" controlId="contato">
                  <Form.Label column sm={2}>
                    Contato
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="text" name="contato" value={formData.contato} readOnly />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="ativo">
                  <Form.Label column sm={2}>
                    Ativo
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      placeholder="Digite o nome do ativo"
                      value={formData.ativo}
                      onChange={handleChange}
                      name="ativo"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="assunto">
                  <Form.Label column sm={2}>
                    Assunto
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="assunto"
                      value={formData.assunto}
                      onChange={handleChange}
                      placeholder="Digite o assunto do ticket"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="servico">
                  <Form.Label column sm={2}>
                    Serviço
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Select name="servico" value={formData.servico} onChange={handleChange}>
                      <option value="">Selecione o serviço</option>
                      <option value="Manutenção">Manutenção</option>
                      <option value="Correção">Correção</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="descricao">
                  <Form.Label column sm={2}>
                    Descrição
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="descricao"
                      value={formData.descricao}
                      onChange={handleChange}
                      placeholder="Descreva a solicitação"
                    />
                  </Col>
                </Form.Group>
                <div className="d-flex justify-content-end">
                  {isLoading ? (
                    <Spinner animation="border" variant="primary" className="me-2" />
                  ) : (
                    <>
                      <Button variant="success" className="me-2" onClick={handleSubmit}>
                        ✅ Salvar
                      </Button>
                      <Button
                        variant="dark"
                        onClick={() =>
                          setFormData((prevData) => ({
                            ...prevData,
                            ativo: "",
                            assunto: "",
                            servico: "",
                            descricao: "",
                          }))
                        }
                      >
                        ❌ Cancelar
                      </Button>
                    </>
                  )}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Card className="shadow-sm rounded">
            <Card.Header className="text-white">
              <h5>Meus Tickets</h5>
            </Card.Header>
            <Card.Body>
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Assunto</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map((ticket) => (
                      <tr key={ticket.ticket_id}>
                        <td>{ticket.ticket_id}</td>
                        <td>{ticket.subject}</td>
                        <td>{ticket.status}</td>
                        <td>
                          <Button
                            variant="info"
                            onClick={() => {
                              setSelectedTicket(ticket);
                              fetchTicketDetails(ticket.ticket_id);
                            }}
                          >
                            Ver Mais
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Remova size="lg" e adicione dialogClassName="custom-modal" */}
      <Modal
        show={!!selectedTicket}
        onHide={() => setSelectedTicket(null)}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton className="text-white">
          <Modal.Title>Detalhes do Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingDetails ? (
            <div className="d-flex justify-content-center my-1">
              <Spinner animation="border" variant="info" />
            </div>
          ) : ticketDetails ? (
            <div className="ticket-details">
              <p>
                <strong>ID:</strong> {ticketDetails.id}
              </p>
              <p>
                <strong>Assunto:</strong> {ticketDetails.subject}
              </p>
              <p>
                <strong>Status:</strong> {ticketDetails.status}
              </p>
              <p>
                <strong>Descrição:</strong> {ticketDetails.description}
              </p>
              <p>
                <strong>Última Atualização:</strong>{" "}
                {new Date(ticketDetails.updated_at).toLocaleString()}
              </p>
              <hr />
              <h5>Comentários:</h5>
              {ticketComments && ticketComments.length > 0 ? (
                <div className="comments-section">
                  {ticketComments.map((comment) => (
                    <div key={comment.id} className="comment p-1 mb-1 bg-light rounded shadow-sm">
                      <div
                        dangerouslySetInnerHTML={{ __html: comment.html_body }}
                        className="comment-body mb-6"
                      />
                      <p className="comment-date mb-0">
                        <small>
                          <strong>Data:</strong>{" "}
                          {new Date(comment.created_at).toLocaleString()}
                        </small>
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <Alert variant="warning">Nenhum comentário disponível para este ticket.</Alert>
              )}
            </div>
          ) : (
            <Alert variant="danger">Erro ao carregar os detalhes do ticket.</Alert>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Tickets;
