import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap"; // Importa componentes do react-bootstrap

const Bancodedados = () => {
  const [ativos, setAtivos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Controle do modal e ativo selecionado para edição
  const [showModal, setShowModal] = useState(false);
  const [selectedAtivo, setSelectedAtivo] = useState(null);
  const [formData, setFormData] = useState({});

  const token = localStorage.getItem("token");

  // Função para buscar os ativos (a API já retorna todos os campos, inclusive "name")
  const fetchAtivos = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.dotadot.com.br/api/v1/ativos", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAtivos(response.data);
      setError(null);
    } catch (err) {
      setError("Erro ao carregar ativos.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAtivos();
  }, [token]);

  // Abre o modal para editar o ativo selecionado
  const handleEdit = (ativo) => {
    setSelectedAtivo(ativo);
    setShowModal(true);

    // Prepara os dados de edição: copia os campos de nível superior
    const newFormData = {
      id: ativo.id || "",
      designacao: ativo.designacao || "",
      name: ativo.name || "",
      dashboardurl: ativo.dashboardurl || "",
      // Clona o objeto custom_object_fields para edição
      custom_object_fields: { ...ativo.custom_object_fields },
    };
    setFormData(newFormData);
  };

  // Fecha o modal e limpa os estados
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAtivo(null);
    setFormData({});
  };

  // Atualiza os campos de nível superior
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Atualiza os campos internos de custom_object_fields
  const handleCustomFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      custom_object_fields: {
        ...prev.custom_object_fields,
        [name]: value,
      },
    }));
  };

  // Salva as alterações enviando uma requisição PUT para o back-end
  const handleSave = async () => {
    try {
      // Atualiza apenas o campo dashboardurl, conforme implementado no back-end
      await axios.put(
        `https://api.dotadot.com.br/api/v1/ativos/${formData.id}/dashboardurl`,
        { dashboardurl: formData.dashboardurl },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Atualiza a lista local para refletir as alterações
      setAtivos((prevAtivos) =>
        prevAtivos.map((ativo) =>
          ativo.id === formData.id ? { ...ativo, dashboardurl: formData.dashboardurl } : ativo
        )
      );
      alert("Ativo atualizado com sucesso!");
      handleCloseModal();
    } catch (err) {
      console.error(err);
      alert("Erro ao atualizar o ativo.");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>Gerenciar Ativos</h2>
      {loading && <p>Carregando ativos...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {!loading && ativos.length === 0 && <p>Nenhum ativo encontrado.</p>}

      {/* Lista com apenas Designação e Nome */}
      {ativos.length > 0 && (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ borderBottom: "1px solid #ccc" }}>
              <th style={{ padding: "10px" }}>Designação</th>
              <th style={{ padding: "10px" }}>Nome</th>
              <th style={{ padding: "10px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {ativos.map((ativo) => (
              <tr key={ativo.id} style={{ borderBottom: "1px solid #eee" }}>
                <td style={{ padding: "10px" }}>{ativo.designacao}</td>
                <td style={{ padding: "10px" }}>{ativo.name}</td>
                <td style={{ padding: "10px" }}>
                  <button onClick={() => handleEdit(ativo)}>Ver/Editar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal de Edição: exibe todos os campos do ativo */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Editar Ativo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAtivo && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Designação:</Form.Label>
                <Form.Control
                  type="text"
                  name="designacao"
                  value={formData.designacao}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Nome:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Dashboard URL:</Form.Label>
                <Form.Control
                  type="text"
                  name="dashboardurl"
                  value={formData.dashboardurl}
                  onChange={handleChange}
                />
              </Form.Group>

              <hr />
              <h5>Campos custom_object_fields</h5>
              {/* Exemplo: listando alguns campos do objeto custom_object_fields */}
              <Form.Group className="mb-3">
                <Form.Label>Provedor:</Form.Label>
                <Form.Control
                  type="text"
                  name="provedor"
                  value={formData.custom_object_fields.provedor || ""}
                  onChange={handleCustomFieldChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>CD_ISP:</Form.Label>
                <Form.Control
                  type="text"
                  name="CD_ISP"
                  value={formData.custom_object_fields.CD_ISP || ""}
                  onChange={handleCustomFieldChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Razão Social:</Form.Label>
                <Form.Control
                  type="text"
                  name="razao_social"
                  value={formData.custom_object_fields.razao_social || ""}
                  onChange={handleCustomFieldChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>CNPJ/CPF:</Form.Label>
                <Form.Control
                  type="text"
                  name="cnpj_cpf_"
                  value={formData.custom_object_fields.cnpj_cpf_ || ""}
                  onChange={handleCustomFieldChange}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Bancodedados;
