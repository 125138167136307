import React, { useState, useEffect } from 'react';
import { 
  Table, 
  Button, 
  Container, 
  Modal, 
  Form, 
  Card, 
  Row, 
  Col 
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { 
  FaUserEdit, 
  FaUserPlus, 
  FaTrashAlt, 
} from 'react-icons/fa';
import 'animate.css';

const VincularUsuario = () => {
  const [users, setUsers] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [editTag, setEditTag] = useState(""); // State para o campo Tag na edição
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCNPJ, setSelectedCNPJ] = useState("");
  const [showAddModal, setShowAddModal] = useState(false); // State para modal de adição

  useEffect(() => {
    fetchUsers();
    fetchClientes();
  }, []);

  const fetchClientes = async () => {
    try {
      const response = await fetch('https://api.dotadot.com.br/api/v1/clientes_adm', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setClientes(data);
      } else {
        Swal.fire('Erro', 'Falha ao buscar clientes.', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://api.dotadot.com.br/api/v1/users', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        Swal.fire('Erro', 'Falha ao buscar usuários.', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  const handleDelete = async (id) => {
    const confirm = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Essa ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir!',
    });

    if (confirm.isConfirmed) {
      try {
        const response = await fetch(`https://api.dotadot.com.br/api/v1/users/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.ok) {
          Swal.fire('Sucesso', 'Usuário excluído com sucesso!', 'success');
          setUsers(users.filter((u) => u.id_usuario !== id));
        } else {
          Swal.fire('Erro', 'Falha ao excluir usuário.', 'error');
        }
      } catch (error) {
        Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
      }
    }
  };

  const startEdit = (user) => {
    setEditUser(user);
    setSelectedCNPJ(user.cnpj || "");
    setEditTag(user.tag || "");
    setShowEditModal(true);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const tagValue = editTag.trim() === "" ? null : editTag.trim();

    const updatedUser = {
      nome_usuario: e.target.elements['nome'].value,
      email: e.target.elements['email'].value,
      username: e.target.elements['username'].value,
      role: e.target.elements['role'].value,
      cnpj: selectedCNPJ,
      tag: tagValue,
    };


    try {
      const response = await fetch(`https://api.dotadot.com.br/api/v1/users/${editUser.id_usuario}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(updatedUser),
      });
      if (response.ok) {
        Swal.fire('Sucesso', 'Usuário atualizado com sucesso!', 'success');
        setShowEditModal(false);
        fetchUsers();
      } else {
        const errorData = await response.json();
        Swal.fire('Erro', errorData.detail || 'Falha ao atualizar usuário.', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    const form = e.target;
    const inputTag = form.elements['tag'].value;
    const tagValue = inputTag.trim() === "" ? null : inputTag.trim();

    const newUser = {
      nome_usuario: form.elements['nome'].value,
      email: form.elements['email'].value,
      username: form.elements['username'].value,
      password: form.elements['password'].value,
      role: form.elements['role'].value,
      cnpj_cliente: form.elements['cnpj'].value,
      tag: tagValue,
    };

    

    try {
      const response = await fetch(`https://api.dotadot.com.br/api/v1/criar-usuarios`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(newUser),
      });
      if (response.ok) {
        Swal.fire('Sucesso', 'Usuário adicionado com sucesso!', 'success');
        setShowAddModal(false);
        fetchUsers();
      } else {
        const errorData = await response.json();
        Swal.fire('Erro', errorData.detail || 'Falha ao adicionar usuário.', 'error');
      }
    } catch (error) {
      Swal.fire('Erro', 'Erro de conexão com o servidor.', 'error');
    }
  };

  const handleClienteSelect = (e) => {
    const selectedCliente = clientes.find(
      (cliente) => cliente.cnpj === e.target.value
    );
    setSelectedCNPJ(selectedCliente ? selectedCliente.cnpj : "");
  };

  return (
    <Container className="mt-5">
      <Card className="shadow-lg mb-4 animate__animated animate__fadeIn">
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <h1 className="text-center">Administração de Usuários</h1>
            </Col>
            <Col xs="auto">
              <Button variant="success" onClick={() => setShowAddModal(true)}>
                <FaUserPlus className="me-2" />
                Adicionar Usuário
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="shadow-lg animate__animated animate__fadeIn">
        <Card.Header className="bg-primary text-white">
          Lista de Usuários
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Username</th>
                <th>Email</th>
                <th>Função</th>
                <th>Tag</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id_usuario}>
                  <td>{user.id_usuario}</td>
                  <td>{user.nome_usuario}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.tag}</td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => startEdit(user)}
                      className="me-2"
                    >
                      <FaUserEdit />
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(user.id_usuario)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
              {users.length === 0 && (
                <tr>
                  <td colSpan="7" className="text-center">
                    Nenhum usuário encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Modal de Edição */}
      {showEditModal && (
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEdit}>
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={editUser?.nome_usuario || ''}
                  name="nome"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  defaultValue={editUser?.email || ''}
                  name="email"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={editUser?.username || ''}
                  name="username"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Função</Form.Label>
                <Form.Control
                  as="select"
                  name="role"
                  defaultValue={editUser?.role || ''}
                  required
                >
                  <option value="">Selecione a função</option>
                  <option value="Funcionario">Funcionario</option>
                  <option value="Cliente">Cliente</option>
                  <option value="Administrador">Administrador</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Tag</Form.Label>
                <Form.Control
                  type="text"
                  name="tag"
                  value={editTag}
                  onChange={(e) => setEditTag(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Cliente</Form.Label>
                <Form.Control
                  as="select"
                  name="cnpj"
                  value={selectedCNPJ}
                  onChange={handleClienteSelect}
                  required
                >
                  <option value="">Selecione um Cliente</option>
                  {clientes.map((cliente) => (
                    <option key={cliente.cnpj} value={cliente.cnpj}>
                      {cliente.razao_social} - {cliente.cnpj}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>CNPJ</Form.Label>
                <Form.Control type="text" value={selectedCNPJ} disabled readOnly />
              </Form.Group>
              <Button variant="primary" type="submit">
                Salvar Alterações
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      {/* Modal de Adição */}
      {showAddModal && (
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleAddUser}>
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control type="text" name="nome" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" name="username" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Senha</Form.Label>
                <Form.Control type="password" name="password" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Função</Form.Label>
                <Form.Control as="select" name="role" required>
                  <option value="">Selecione a função</option>
                  <option value="Funcionario">Funcionario</option>
                  <option value="Cliente">Cliente</option>
                  <option value="Administrador">Administrador</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Tag</Form.Label>
                <Form.Control type="text" name="tag" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Cliente</Form.Label>
                <Form.Control
                  as="select"
                  name="cnpj"
                  value={selectedCNPJ}
                  onChange={handleClienteSelect}
                  required
                >
                  <option value="">Selecione um Cliente</option>
                  {clientes.map((cliente) => (
                    <option key={cliente.cnpj} value={cliente.cnpj}>
                      {cliente.razao_social} - {cliente.cnpj}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button variant="primary" type="submit">
                Adicionar Usuário
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

export default VincularUsuario;
